import React, { useState } from "react";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Actions
import { failure } from "~/redux/actions/snackbar-actions";
import {
  downgradePlanCost,
  upgradePlanCost,
} from "~/redux/actions/stripe-actions";

// Utils
import { MESSAGES } from "~/utils/message";

// MUI Components
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Tooltip,
} from "@mui/material";

// styles
import "./styles.scss";

const index = (props) => {
  const {
    image,
    title,
    features,
    plan,
    billAnnual,
    billMonthly,
    annual,
    isActive,
    currentPlanStripId,
    currentPlanType,
    stripeId,
    handleOpen,
    handleDowngradeOpen,
    plansOrder,
    currentPlan,
    selectedType,
    selectedPlanType,
    setPlanDetails,
  } = props;
  const [isViewMore, setIsViewMore] = useState(false);

  const {
    users,
    webhooks,
    userRolesPermissions,
    templates,
    scheduleMailing,
    mailingLimit,
    mailTracking,
    contacts,
    ayalyticsDashboard,
    customFields,
    tags,
  } = features;

  const orderArr = ["starter", "growth", "professional", "enterprise"];
  const currentIndex = orderArr.indexOf(currentPlan?.slug);
  const selectedIndex = orderArr.indexOf(selectedType);
  const [isLoading, setIsLoading] = useState(false);

  const isAdmin =
    useSelector((state) => state.userReducers?.user?.isOrgAdmin) || false;

  const isSystemAdmin = useSelector((state) => state?.userReducers?.isSystemAdmin) || false;
  
  const dispatch = useDispatch();

  const stripeIdies = plansOrder.map((plan) => plan.stripeId);

  const findIndex = (stripeId) => {
    return stripeIdies.findIndex((id) => id === stripeId);
  };

  const downgradeRequest = async (stripeId) => {
    try {
      setIsLoading(true);
      const response = await downgradePlanCost({
        planId: stripeId,
      });
      if (response.status === 200) {
        setPlanDetails(response.data.data);
        handleDowngradeOpen(stripeId);
      } else {
        dispatch(failure(response?.data?.message));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data?.message || error?.message));
    } finally {
      setIsLoading(false);
    }
  };

  const upgradeRequest = async (stripeId) => {
    try {
      setIsLoading(true);
      const response = await upgradePlanCost({ planId: stripeId });
      if (response.status === 200) {
        setPlanDetails(response.data.data);
        handleOpen(stripeId);
      } else {
        dispatch(failure(response?.data?.message));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data?.message || error?.message));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      className={`${
        isActive ? `cardWrapperActive cardWrapper` : `cardWrapper`
      } ${isViewMore ? `heightIncrease` : "heightDecrease"}`}
    >
      <Box className="cardHead">
        <img src={image} alt="plans" />
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Box className="featuresWrapper">
        {users.show && (
          <Box className="feature">
            <Typography>
              {MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.LABELS.USERS}
            </Typography>
            <Typography>
              {users.limit === -1 || isSystemAdmin ? "Unlimited" : users.limit}
            </Typography>
          </Box>
        )}
        {contacts.show && (
          <Box className="feature">
            <Typography>
              {MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.LABELS.CONTACTS}
            </Typography>
            <Typography>
              {contacts.limit === -1 || isSystemAdmin
                ? "Unlimited"
                : `Up to ${contacts.limit}`}
            </Typography>
          </Box>
        )}
        {templates.show && (
          <Box className="feature">
            <Typography>
              {MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.LABELS.TEMPLATES}
            </Typography>
            <Typography>
              {templates.limit === -1 || isSystemAdmin
                ? "Unlimited"
                : templates.limit}
            </Typography>
          </Box>
        )}
        {customFields.show && (
          <Box className="feature">
            <Typography>
              {MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.LABELS.CUSTOM_FIELDS}
            </Typography>
            <Typography>
              {customFields.limit === -1 || isSystemAdmin
                ? "Unlimited"
                : customFields.limit}
            </Typography>
          </Box>
        )}
        {webhooks.show && (
          <Box className="feature">
            <Typography>
              {MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.LABELS.WEBHOOKS}
            </Typography>
            <Typography>
              {webhooks.limit === -1 || isSystemAdmin ? "Unlimited" : webhooks.limit}
            </Typography>
          </Box>
        )}
        {tags?.show && (
          <Box className="feature">
            <Typography>
              {MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.LABELS.TAGS}
            </Typography>
            <Typography>
              {tags.limit === -1 || isSystemAdmin ? "Unlimited" : tags.limit}
            </Typography>
          </Box>
        )}
        {isViewMore && (
          <>
            {mailingLimit.show && (
              <Box className="feature">
                <Typography>
                  {MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.LABELS.MAILING}
                </Typography>
                <Typography>
                  {mailingLimit.limit === -1 || isSystemAdmin
                    ? "Unlimited"
                    : `Up to ${mailingLimit.limit}`}
                </Typography>
              </Box>
            )}
            {mailTracking.show && (
              <Box className="feature">
                <Typography>
                  {MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.LABELS.TRANSIT}
                </Typography>
                <Typography>{mailTracking.limit || isSystemAdmin ? "Yes" : "No"} </Typography>
              </Box>
            )}
            {userRolesPermissions.show && (
              <Box className="feature">
                <Typography>
                  {MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.LABELS.ROLES_PERMISSION}
                </Typography>
                <Typography>
                  {userRolesPermissions.limit || isSystemAdmin ? "Yes" : "No"}{" "}
                </Typography>
              </Box>
            )}
            {ayalyticsDashboard.show && (
              <Box className="feature">
                <Typography>
                  {MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.LABELS.ANALYTICS}
                </Typography>
                <Typography>
                  {ayalyticsDashboard.limit || isSystemAdmin ? "Advanced" : "Basic"}{" "}
                </Typography>
              </Box>
            )}
            {scheduleMailing.show && (
              <Box className="feature">
                <Typography>
                  {MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.LABELS.MAILER}
                </Typography>
                <Typography>{scheduleMailing.limit || isSystemAdmin ? "Yes" : "No"} </Typography>
              </Box>
            )}
          </>
        )}

        <Box className="details">
          <Typography
            onClick={() => setIsViewMore((prev) => !prev)}
            className="view-more-less"
          >
            {isViewMore ? "View Less" : "View more details"}
          </Typography>
          <Typography>
            {currentPlan?.slug === "enterprise" && currentPlan?.name === title
              ? ""
              : plan}
          </Typography>
          {plan === "Free" ? "" : (
            <Typography className="billAnnual">
              {annual && billAnnual.length > 0 ? `(${billAnnual})` : ""}
            </Typography>
          )}
          {!annual ? (
            <Typography className="billAnnual">
              {billAnnual.length > 0 ? `(${billAnnual})` : ""}
            </Typography>
          ) : (
            ""
          )}
        </Box>
      </Box>
      {currentPlanStripId == stripeId ? (
        <Button className="currentPlanButton">
          {MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.CURRENT_PLAN_BTN}
        </Button>
      ) : (
        <>
          {plan !== "Talk to Sales" &&
          currentIndex === selectedIndex &&
          currentPlanType !== selectedPlanType ? (
            <>
              {currentPlanType === "year" ? (
                <Tooltip
                  title={
                    !isAdmin
                      ? "Only the Admins on your account are authorized to downgrade the plan."
                      : ""
                  }
                >
                  <Button
                    onClick={() =>
                      !isAdmin ? undefined : downgradeRequest(stripeId)
                    }
                    className={!isAdmin ? "disabled" : ""}
                  >
                    {isLoading ? (
                      <CircularProgress
                        sx={{
                          color: "white",
                          width: "25px !important",
                          height: "25px !important",
                        }}
                      />
                    ) : (
                      MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.DOWNGRADE_PLAN_BTN
                    )}
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    !isAdmin
                      ? "Only the Admins on your account are authorized to upgrade the plan."
                      : ""
                  }
                >
                  <Button
                    onClick={() =>
                      !isAdmin ? undefined : upgradeRequest(stripeId)
                    }
                    className={!isAdmin ? "disabled" : ""}
                  >
                    {isLoading ? (
                      <CircularProgress
                        sx={{
                          color: "white",
                          width: "25px !important",
                          height: "25px !important",
                        }}
                      />
                    ) : (
                      MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.UPGRADE_PLAN_BTN
                    )}
                  </Button>
                </Tooltip>
              )}
            </>
          ) : plan !== "Talk to Sales" && currentIndex <= selectedIndex ? (
            <Tooltip
              title={
                !isAdmin
                  ? "Only the Admins on your account are authorized to upgrade the plan."
                  : ""
              }
            >
              <Button
                onClick={() =>
                  !isAdmin ? undefined : upgradeRequest(stripeId)
                }
                className={!isAdmin ? "disabled" : ""}
              >
                {isLoading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.UPGRADE_PLAN_BTN
                )}
              </Button>
            </Tooltip>
          ) : (
            plan !== "Talk to Sales" &&
            currentIndex >= selectedIndex && (
              <Tooltip
                title={
                  !isAdmin
                    ? "Only the Admins on your account are authorized to downgrade the plan."
                    : ""
                }
              >
                <Button
                  onClick={() =>
                    !isAdmin ? undefined : downgradeRequest(stripeId)
                  }
                  className={!isAdmin ? "disabled" : ""}
                >
                  {isLoading ? (
                    <CircularProgress
                      sx={{
                        color: "white",
                        width: "25px !important",
                        height: "25px !important",
                      }}
                    />
                  ) : (
                    MESSAGES.ACCOUNT.PLANS.PLAN_CARDS.DOWNGRADE_PLAN_BTN
                  )}
                </Button>
              </Tooltip>
            )
          )}
        </>
      )}
    </Box>
  );
};

export default index;
