import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { success, failure } from "~/redux/actions/snackbar-actions";
import { dispatchOrderNowByAdmin, fetchAdminOrders } from "~/redux/actions/admin-orders-actions";

// Third Party Libraries
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Utils
import { MESSAGES } from "~/utils/message";
import {
  dateProtector,
  handleRawChangeForDatePicker,
} from "~/utils/date-format";

// Mui Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress, InputLabel } from "@mui/material";

// Icons
import { ModalCrossCIcon } from "~/assets/images";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DispatchNowModal = (props) => {
  const { openModal, handleCloseModal, selectedOrder } = props;
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [dispatchDate] = dateRange;

  const dispatch = useDispatch();

  const onSubmit = async () => {
    try {
      setLoading(true);
      const payload = {
        orderIds: [selectedOrder],
        ...(dispatchDate && { mailDate: dispatchDate }),
      };
      const response = await dispatch(dispatchOrderNowByAdmin(payload));
      if (response.status === 200) {
        dispatch(success(response?.data?.message));
        dispatch(fetchAdminOrders());
        handleCloseModal();
      } else {
        dispatch(failure(response?.data?.message));
      }
    } catch (error) {
      dispatch(failure(error?.response?.data?.message || error?.message));
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };

  useEffect(() => {
    setDateRange([null, null]);
  }, [openModal])

  return (
    <BootstrapDialog
      onClose={handleCloseModal}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      className="userActionModalWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={handleCloseModal}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: 0,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ModalCrossCIcon />
      </IconButton>
      <DialogContent dividers className="userActionModalContent">
        <Box className="deleteHeadings">
          <Typography gutterBottom variant="h4">
            {MESSAGES.ADMIN_DASHBOARD.ORDERS.DISPATCH_ACTION.TITLE}
          </Typography>
          <Typography gutterBottom>
            {MESSAGES.ADMIN_DASHBOARD.ORDERS.DISPATCH_ACTION.HEADING}
          </Typography>
        </Box>
        <Box className="mailedDate">
                  <Box className="selectDate">
                    <InputLabel className="dateLabel">
                      {MESSAGES.ADMIN_DASHBOARD.ORDERS.DISPATCH_ACTION.DISPATCH_DATE_LABEL}
                    </InputLabel>
                    <KeyboardArrowDownIcon
                      sx={{
                        color: isDatepickerOpen ? "#ED5C2F" : "inherit",
                      }}
                    />
                    <DatePicker
                      minDate={new Date()}
                      onCalendarClose={() => setIsDatepickerOpen(false)}
                      selected={dispatchDate}
                      onChangeRaw={(e) => handleRawChangeForDatePicker(e, setDateRange)}
                      onChange={(date) => {
                        dateProtector(date);
                        setDateRange([date, null]); // Keep only one date
                      }}
                      onFocus={() => setIsDatepickerOpen(true)}
                      onBlur={(e) => {
                        if (e.target.value === "") {
                          setDateRange([null, null]);
                        }
                        setIsDatepickerOpen(false);
                      }}
                      placeholderText={MESSAGES.ADMIN_DASHBOARD.ORDERS.DISPATCH_ACTION.DISPATCH_DATE_LABEL}
                    />
                  </Box>
                </Box>
        <Box className="userActionModalBtns">
          <Button onClick={handleCloseModal}>
            {MESSAGES.ADMIN_DASHBOARD.ORDERS.DISPATCH_ACTION.CANCEL_BUTTON}
          </Button>
          <Button onClick={onSubmit} disabled={loading}>
            {loading ? (
              <CircularProgress
                sx={{
                  color: "white",
                  width: "25px !important",
                  height: "25px !important",
                }}
              />
            ) : (
              MESSAGES.ADMIN_DASHBOARD.ORDERS.DISPATCH_ACTION.SUBMIT_BUTTON
            )}
          </Button>
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

export default DispatchNowModal;
