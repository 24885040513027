import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  useMemo,
} from "react";

// Third Party Libraries 
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Hooks
import { useDispatch, useSelector } from "react-redux";

// Action
import {
  SET_ORDERS_FILTERS,
  CLEAR_ORDERS_FILTERS,
} from "~/redux/actions/action-types";
import { failure } from "~/redux/actions/snackbar-actions";

// Utils
import { MESSAGES } from "~/utils/message";
import { DEFAULT_PAGINATION } from "~/utils/constants";
import {
  dateProtector,
  handleRawChangeForDatePicker,
} from "~/utils/date-format";

// Components
import MultiSelect from "~/components/Contacts/AdvanceFilters/MultiSelect";
import GenericAutocomplete from "~/components/General/Autocomplete";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Typography from "@mui/material/Typography";
import { Box, Chip, Grid, InputLabel, Input } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AdvanceFilters = forwardRef((props, ref) => {
  const { handleCloseFilter, open, updatePagination } = props;
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [selectedProductType, setSelectedProductType] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState([]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]);
  const [createdDate, setCreatedDate] = useState([null, null]);
  const [scheduledDate, setScheduledDate] = useState([null, null]);
  const [startDateCreated, endDateCreated] = createdDate;
  const [startDateScheduled, endDateScheduled] = scheduledDate;
  const [isDatepickerOpen, setIsDatepickerOpen] = useState(false);
  const [scheduleDatepicker, setScheduleDatepicker] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [metaData, setMetaData] = useState({});

  const dispatch = useDispatch();

  const productTypes = useSelector((state) => state.productReducers.types);
  const sources = useSelector(
    (state) => state.ordersReducers.filterslabels.source
  );

  const createdBy = useSelector(
    (state) => state.ordersReducers.filterslabels.users
  );

  const requiredMetaFields = useSelector(
    (state) => state.ordersReducers.filterslabels.requiredMetaFields
  );

  // Ensure createdBy is defined before mapping
  // const createdByOptions = createdBy ? createdBy.map(user => ({
  //   label: `${user.title.firstName} (${user.title.email})`,
  //   id: user.title.id
  // })) : [];
  const createdByOptions = useMemo(() => {
    return createdBy
      ? createdBy.map((user) => ({
          label: `${user.title.firstName} (${user.title.email})`,
          id: user.title.id,
        }))
      : [];
  }, [createdBy]);
  const orderStatus = useSelector(
    (state) => state.ordersReducers.filterslabels.orderStatus
  );

  const paymentStatus = useSelector(
    (state) => state.ordersReducers.filterslabels.paymentStatus
  );

  const perPage = useSelector(
    (state) => state.ordersReducers.perPage || DEFAULT_PAGINATION.pageSize
  );

  const currentMode = useSelector(
    (state) => state.userReducers.user.isLiveMode || false
  );

  const filters = useSelector((state) => state.ordersReducers.filters);

  const onClose = () => {
    clearFilters(true);
  };

  const clearFilters = (persist = false) => {
    setSelectedCreatedBy([]);
    setSelectedProductType([]);
    setSelectedSources([]);
    setSelectedOrderStatus([]);
    setSelectedPaymentStatus([]);
    setCreatedDate([null, null]);
    setScheduledDate([null, null]);
    setMetaData({});
    !persist ? dispatch({ type: CLEAR_ORDERS_FILTERS }) : undefined;
    handleCloseFilter();
  };

  const applyFilters = () => {
    const createdDate =
      startDateCreated && endDateCreated
        ? {
            startDate: startDateCreated.toISOString().slice(0, 10),
            endDate: endDateCreated.toISOString().slice(0, 10),
          }
        : null;

    if (startDateCreated && endDateCreated === null) {
      dispatch(failure(MESSAGES.ORDERS.END_DATE_REQUIRED));
      return;
    }

    const scheduledDate =
      startDateScheduled && endDateScheduled
        ? {
            startDate: startDateScheduled.toISOString().slice(0, 10),
            endDate: endDateScheduled.toISOString().slice(0, 10),
          }
        : null;

    if (startDateScheduled && endDateScheduled === null) {
      dispatch(failure(MESSAGES.ORDERS.END_DATE_REQUIRED));
      return;
    }

    let payload = {
      applyFilters: true,
      filters: {
        createdBy:
          selectedCreatedBy && selectedCreatedBy?.id
            ? [Number(selectedCreatedBy?.id)]
            : [],
        productTypes: selectedProductType,
        sources: selectedSources,
        orderStatus: selectedOrderStatus,
        paymentStatus: selectedPaymentStatus,
        createdDate: createdDate,
        scheduledDate: scheduledDate,
        ...(Object.keys(metaData).length > 0 && Object.values(metaData).some(value => value !== '') && { metaData })
      },
    };

    const filtersWithValues = Object.keys(payload.filters).filter((key) => {
      const value = payload.filters[key];
      return Array.isArray(value) ? value.length > 0 : value !== null;
    });

    payload.filterSize = filtersWithValues.length;

    updatePagination({
      pageSize: perPage,
      page: 1,
    });

    if (filtersWithValues.length <= 0) {
      dispatch(failure(MESSAGES.ORDERS.FILTERS_REQUIRED));
      return;
    }

    dispatch({ type: SET_ORDERS_FILTERS, payload: payload });
    onClose();
  };

  const searching = async () => {
    setLoading(true);
    try {
      if (!search) {
        setFilteredOptions(createdByOptions);
      } else {
        const filtered = createdByOptions.filter((option) =>
          option.label.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredOptions(filtered);
      }
    } catch (error) {
      console.error("Error filtering users:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateMetaDataFields = (key, value) => {
    setMetaData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useImperativeHandle(ref, () => ({
    clearFilters() {
      clearFilters();
    },
  }));

  useEffect(() => {
    clearFilters();
  }, [currentMode]);

  useEffect(() => {
    if (Object.values(filters).length) {
      if (filters.createdBy) {
        const selectedUser = createdBy.find(
          (user) => user.title.id == filters.createdBy
        );
        setSelectedCreatedBy(
          selectedUser
            ? {
                id: selectedUser.title.id,
                label: `${selectedUser.title.firstName} (${selectedUser.title.email})`,
              }
            : null
        );
      }
      setMetaData(filters.metaData || {});
      setSelectedProductType(filters.productTypes);
      setSelectedSources(filters.sources);
      setSelectedOrderStatus(filters.orderStatus);
      setSelectedPaymentStatus(filters.paymentStatus);
      filters.createdDate
        ? setCreatedDate([
            new Date(filters?.createdDate?.startDate),
            new Date(filters?.createdDate?.endDate),
          ])
        : undefined;
      filters.scheduledDate
        ? setScheduledDate([
            new Date(filters?.scheduledDate?.startDate),
            new Date(filters?.scheduledDate?.endDate),
          ])
        : undefined;
    }
  }, [open]);

  useEffect(() => {
    searching();
  }, [search, createdByOptions]);

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="ordersFilterModalWrapper"
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 4,
            top: 4,
          }}
        >
          <HighlightOffIcon />
        </IconButton>
        <DialogContent className="ordersFilterContent" dividers>
          <Box className="ordersFilterHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.ORDERS.ADVANCE_FILTERS.HEADING}
            </Typography>
          </Box>
          <Box className="ordersFilterTagsWrapper">
            <Grid container className="ordersFilterTagContent" spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="addressMailWrapper">
                  <Box className="selectAddress">
                    <InputLabel className="addressLabel">
                      {MESSAGES.ORDERS.ADVANCE_FILTERS.CREATED_BY_LABEL}
                    </InputLabel>
                    <GenericAutocomplete
                      placeholder={
                        MESSAGES.ORDERS.ADVANCE_FILTERS.CREATED_BY_PLACEHOLDER
                      }
                      options={filteredOptions}
                      selectedValue={selectedCreatedBy}
                      setSelectedValue={setSelectedCreatedBy}
                      search={setSearch}
                      updateErrors={() => {}}
                      loading={loading}
                      filterAutoComplete
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className="addressMailWrapper">
                  <Box className="selectAddress">
                    <InputLabel className="addressLabel">
                      {MESSAGES.ORDERS.ADVANCE_FILTERS.PRODUCT_TYPE_LABEL}
                    </InputLabel>
                    <MultiSelect
                      options={productTypes}
                      selectedValue={selectedProductType}
                      setSelectedValue={setSelectedProductType}
                      placeholder={
                        MESSAGES.ORDERS.ADVANCE_FILTERS.PRODUCT_TYPE_LABEL
                      }
                      noOptionText={
                        MESSAGES.ORDERS.ADVANCE_FILTERS.NO_PRODUCT_TYPE
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className="addressMailWrapper">
                  <Box className="selectAddress">
                    <InputLabel className="addressLabel">
                      {MESSAGES.ORDERS.ADVANCE_FILTERS.SOURCE_LABEL}
                    </InputLabel>
                    <MultiSelect
                      options={sources}
                      selectedValue={selectedSources}
                      setSelectedValue={setSelectedSources}
                      placeholder={MESSAGES.ORDERS.ADVANCE_FILTERS.SOURCE_LABEL}
                      noOptionText={MESSAGES.ORDERS.ADVANCE_FILTERS.NO_SOURCE}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className="addressMailWrapper">
                  <Box className="selectAddress">
                    <InputLabel className="addressLabel">
                      {MESSAGES.ORDERS.ADVANCE_FILTERS.ORDER_STATUS_LABEL}
                    </InputLabel>
                    <MultiSelect
                      options={orderStatus}
                      selectedValue={selectedOrderStatus}
                      setSelectedValue={setSelectedOrderStatus}
                      placeholder={
                        MESSAGES.ORDERS.ADVANCE_FILTERS.ORDER_STATUS_LABEL
                      }
                      noOptionText={
                        MESSAGES.ORDERS.ADVANCE_FILTERS.NO_ORDER_STATUS
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className="addressMailWrapper">
                  <Box className="selectAddress">
                    <InputLabel className="addressLabel">
                      {MESSAGES.ORDERS.ADVANCE_FILTERS.PAYMENT_STATUS_LABEL}
                    </InputLabel>
                    <MultiSelect
                      options={paymentStatus}
                      selectedValue={selectedPaymentStatus}
                      setSelectedValue={setSelectedPaymentStatus}
                      placeholder={
                        MESSAGES.ORDERS.ADVANCE_FILTERS.PAYMENT_STATUS_LABEL
                      }
                      noOptionText={
                        MESSAGES.ORDERS.ADVANCE_FILTERS.NO_PAYMENT_STATUS
                      }
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className="mailedDate">
                  <Box className="selectDate">
                    <InputLabel className="dateLabel">
                      {MESSAGES.ORDERS.ADVANCE_FILTERS.CREATED_DATE_LABEL}
                    </InputLabel>
                    <KeyboardArrowDownIcon
                      sx={{
                        color: isDatepickerOpen ? "#ED5C2F" : "inherit",
                      }}
                    />
                    <DatePicker
                      minDate={null}
                      toggleCalendarOnIconClick={true}
                      selectsRange={true}
                      startDate={startDateCreated}
                      endDate={endDateCreated}
                      onChange={(update) => {
                        dateProtector(update);
                        setCreatedDate(update);
                      }}
                      onChangeRaw={(e) =>
                        handleRawChangeForDatePicker(e, setCreatedDate)
                      }
                      onCalendarClose={() => setIsDatepickerOpen(false)}
                      onFocus={() => setIsDatepickerOpen(true)}
                      onBlur={(e) => {
                        if (e.target.value == "") {
                          setCreatedDate([null, null]);
                        }
                        setIsDatepickerOpen(false);
                      }}
                      placeholderText={`${MESSAGES.ORDERS.ADVANCE_FILTERS.CREATED_DATE_LABEL}`}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Box className="mailedDate">
                  <Box className="selectDate">
                    <InputLabel className="dateLabel">
                      {MESSAGES.ORDERS.ADVANCE_FILTERS.SCHEDULED_DATE_LABEL}
                    </InputLabel>
                    <KeyboardArrowDownIcon
                      sx={{
                        color: scheduleDatepicker ? "#ED5C2F" : "inherit",
                      }}
                    />
                    <DatePicker
                      minDate={null}
                      onCalendarClose={() => setScheduleDatepicker(false)}
                      onFocus={() => setScheduleDatepicker(true)}
                      onBlur={(e) => {
                        if (e.target.value == "") {
                          setScheduledDate([null, null]);
                        }
                        setIsDatepickerOpen(false);
                      }}
                      toggleCalendarOnIconClick={true}
                      selectsRange={true}
                      startDate={startDateScheduled}
                      endDate={endDateScheduled}
                      onChange={(update) => {
                        dateProtector(update);
                        setScheduledDate(update);
                      }}
                      onChangeRaw={(e) =>
                        handleRawChangeForDatePicker(e, setScheduledDate)
                      }
                      placeholderText={`${MESSAGES.ORDERS.ADVANCE_FILTERS.SCHEDULED_DATE_LABEL}`}
                    />
                  </Box>
                </Box>
              </Grid>
              {requiredMetaFields?.length > 0 &&
                requiredMetaFields.map((metaField) => (
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Box className="addressMailWrapper">
                      <Box className="selectAddress">
                        <InputLabel className="addressLabel">
                          {metaField?.title}
                        </InputLabel>
                        <Input
                          className="createdByInput"
                          value={metaData[metaField?.title] || ""}
                          name="searchCreator"
                          onChange={(e) => {
                            updateMetaDataFields(
                              metaField?.title,
                              e.target.value
                            );
                          }}
                          type="text"
                          placeholder={metaField?.title}
                        />
                      </Box>
                    </Box>
                  </Grid>
                ))}
            </Grid>
            <Box className="ordersFilterBtn">
              <Button onClick={() => clearFilters()}>
                {MESSAGES.ORDERS.ADVANCE_FILTERS.CANCEL_BUTTON}
              </Button>
              <Button onClick={applyFilters}>
                {MESSAGES.ORDERS.ADVANCE_FILTERS.SUBMIT_BUTTON}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
});

export default AdvanceFilters;
