import React, { useEffect, useState } from "react";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { addFundsToUserByAdmin } from "~/redux/actions/admin-users-actions.js";
import { success } from "~/redux/actions/snackbar-actions.js";

// utils
import { MESSAGES } from "~/utils/message.js";
import { numberWithComma } from "~/utils/helperFunctions.js";

//MUI Component
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Box, Input, InputLabel, CircularProgress } from "@mui/material";

// Icons
import { ModalCrossCIcon } from "~/assets/images";

// Styles
import "./styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const AddFundsByAdmin = (props) => {
  const { handleClose, open, selectedUser } = props;

  const [fund, setFund] = useState("");
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [reasonError, setReasonError] = useState("");
  const [respError, setRespError] = useState("");
  const [activeTab, setActiveTab] = useState(null);

  const dispatch = useDispatch();

  const handleAddFunding = async () => {
    try {
      const payload = {
        id: selectedUser?.id,
        amount: parseFloat(fund.toString().replaceAll(",", "")),
        reason,
      };
      if (!payload.amount || payload.amount > 999999) {
        !payload.amount &&
          setError(MESSAGES.ACCOUNT.WALLET.STRIPE.PLEASE_ENTER_VALID_AMOUNT);
        payload.amount > 999999 &&
          setError(MESSAGES.ACCOUNT.WALLET.STRIPE.AMOUNT_VALIDATION_ERROR);
        return;
      } else {
        setError("");
      }
      if (payload.reason && payload.reason.length > 200) {
        setReasonError(
          MESSAGES.ACCOUNT.WALLET.STRIPE.REASON_VALIDATION_MESSAGE
        );
        return;
      } else {
        setReasonError("");
      }
      setLoading((prev) => !prev);
      const data = await dispatch(addFundsToUserByAdmin(payload));

      if (data.status === 200) {
        handleClose();
        dispatch(
          success(
            data?.data?.message ||
              MESSAGES.ACCOUNT.WALLET.STRIPE.ADD_FUNDS_SUCCESS,
            MESSAGES.ACCOUNT.WALLET.STRIPE.FUNDS_ADDED
          )
        );
      } else {
        setRespError(data.data.message);
      }
    } catch (error) {
      setRespError("An error occurred while adding funds.");
    } finally {
      setLoading((prev) => false);
    }
  };

  const handleChangeInput = (value, tab) => {
    if (typeof value == "string") {
      let valueToAsign = value.replaceAll("$", "").replaceAll(",", "");
      if (valueToAsign !== "" && !/^-?\d*\.?\d{0,2}$/.test(valueToAsign))
        return;
      valueToAsign = numberWithComma(valueToAsign);
      setFund(valueToAsign);
    } else {
      value = numberWithComma(value);
      setFund(value);
    }
    if (error && value >= 50) {
      setError("");
    }
    setActiveTab(tab);
  };

  useEffect(() => {
    setRespError("");
    setError("");
    setReasonError("");
    setFund("");
    setReason("");
    setActiveTab(null);
  }, [open]);

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="fundsModalWrapper"
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
          padding: "0",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ModalCrossCIcon />
      </IconButton>
      <DialogContent className="fundsModalContent">
        <Box className="fundsHeader">
          <Typography gutterBottom variant="h4">
            {MESSAGES.ACCOUNT.WALLET.STRIPE.ADD_FUNDS}
          </Typography>
        </Box>
        <Box className="amountInputWrapper">
          <InputLabel className="fundsLabel">
            {MESSAGES.ACCOUNT.WALLET.STRIPE.INCREASE_WALLET_BALANCE_BY}:
          </InputLabel>
          <Input
            className="fundsInputField"
            type="text"
            placeholder="$10,000.00"
            value={fund ? `$${fund}` : fund}
            onChange={(e) => handleChangeInput(e.target.value)}
          />
          {error && <Typography className="errorMessage">{error}</Typography>}
          <Box className="amountsWrapper">
            <Typography
              onClick={() => handleChangeInput(500, 1)}
              className={`amountBtn ${activeTab === 1 ? "activeAmount" : ""}`}
            >
              $500
            </Typography>
            <Typography
              onClick={() => handleChangeInput(1000, 2)}
              className={`amountBtn ${activeTab === 2 ? "activeAmount" : ""}`}
            >
              $1,000
            </Typography>
            <Typography
              onClick={() => handleChangeInput(2500, 3)}
              className={`amountBtn ${activeTab === 3 ? "activeAmount" : ""}`}
            >
              $2,500
            </Typography>
            <Typography
              onClick={() => handleChangeInput(5000, 4)}
              className={`amountBtn ${activeTab === 4 ? "activeAmount" : ""}`}
            >
              $5,000
            </Typography>
            <Typography
              onClick={() => handleChangeInput(10000, 5)}
              className={`amountBtn ${activeTab === 5 ? "activeAmount" : ""}`}
            >
              $10,000
            </Typography>
          </Box>
        </Box>
        <Box className="amountInputWrapper mt-20">
          <InputLabel className="fundsLabel">
            {MESSAGES.ACCOUNT.WALLET.STRIPE.REASON_FOR_INCREASE}:
          </InputLabel>
          <Input
            className="fundsInputField"
            type="text"
            placeholder={MESSAGES.ACCOUNT.WALLET.STRIPE.REASON_FOR_INCREASE}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          {reasonError && (
            <Typography className="errorMessage">{reasonError}</Typography>
          )}
        </Box>
        <Box className="fundsModalBtns">
          <Button onClick={handleClose}>
            {MESSAGES.ACCOUNT.WALLET.STRIPE.CANCEL}
          </Button>
          <Button disabled={loading} onClick={handleAddFunding}>
            {loading ? (
              <CircularProgress
                sx={{
                  color: "white",
                  width: "25px !important",
                  height: "25px !important",
                }}
              />
            ) : (
              MESSAGES.ACCOUNT.WALLET.STRIPE.SUBMIT
            )}
          </Button>
        </Box>
        {respError && (
          <Typography className="fundsModalContent__error">
            {respError}{" "}
          </Typography>
        )}
      </DialogContent>
    </BootstrapDialog>
  );
};

export default AddFundsByAdmin;
