import React, { useState } from "react";

// Validation libraries
import { useFormik } from "formik";
import * as yup from "yup";

// Hooks
import { useDispatch } from "react-redux";

// Actions
import { success, failure } from "~/redux/actions/snackbar-actions";
import { createTag } from "~/redux/actions/tags-actions";

// Utils
import { MESSAGES } from "~/utils/message";
import {
  validateForm,
  realTimeValidation,
  resetValidation,
} from "~/utils/formik-validation";

// MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box, InputLabel, TextField, CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";

// icons
import { TemplateCrossCIcon, CrossModalCIcon } from "~/assets/images";

// styles
import "./styles.scss";
import { useEffect } from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const CreateTagModal = (props) => {
  const {
    openModal,
    handleCloseModal,
    setSelectedTags,
    setTags = false,
    tagsClass = false,
  } = props;

  const [loading, setLoading] = useState(false);
  const [tagsToCreate, setTagsToCreate] = useState([]);
  const [customErrors, setCustomErrors] = useState([]);

  const dispatch = useDispatch();

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .test("check-count", MESSAGES.SETTINGS.TAGS.CREATE.TAG_LIMIT, (value) => {
        if (!value) return true;

        const tags = value.split(",");
        for (const tag of tags) {
          if (tag.trim().length > 35) {
            return false;
          }
        }
        return true;
      }),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
  });

  const handleEnter = (e, enter = false) => {
    if ((enter || e.key === "Enter") && formik.values.name.trim() !== "") {
      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      const tags = [
        ...new Set([
          ...tagsToCreate,
          ...formik.values.name
            .trim()
            .split(",")
            .map((tag) => tag.trim())
            .filter(Boolean),
        ]),
      ];
      setTagsToCreate(tags);
      setCustomErrors([]);
      formik.handleReset();
    }
  };

  const handleChipsCross = (e) => {
    const changedSet = tagsToCreate.filter((el) => el != e.target.alt);
    setTagsToCreate(changedSet);
  };

  const onClose = () => {
    handleCloseModal();
    formik.handleReset();
    resetValidation();
    setTagsToCreate([]);
    setCustomErrors([]);
  };

  const createNewTag = async (event) => {
    try {
      event.preventDefault();
      handleEnter(event, true);
      let concatedValue = null;
      if (Object.keys(formik.errors).length < 1 && tagsToCreate.length == 0) {
        if (formik.values.name.length) {
          concatedValue = formik.values.name
            .split(",")
            .map((tag) => tag.trim())
            .filter(Boolean);
        } else {
          return setCustomErrors([MESSAGES.SETTINGS.TAGS.TAGS_REQUIRED]);
        }
      }

      const isValid = validateForm(formik);
      if (!isValid) {
        return;
      }
      setLoading(true);
      const payload = {
        tags: tagsToCreate.length ? tagsToCreate : concatedValue,
      };
      const response = await dispatch(createTag(payload));
      dispatch(success(response?.data?.message));
      const createdTags = response?.data?.data || [];
      if (createdTags.length) {
        if (setTags) {
          setSelectedTags((prev) => [
            ...prev,
            ...createdTags.map((tag) => tag.title),
          ]);
        } else if (tagsClass) {
          setSelectedTags([...createdTags]); // Ensure it's a new array reference
        }
      }
      onClose();
    } catch (error) {
      dispatch(failure(error.response.data.message));
      return error;
    } finally {
      setLoading(false);
      setTagsToCreate([]);
    }
  };

  useEffect(() => {
    if (customErrors.length > 0) {
      setCustomErrors([]);
    }
  }, [formik.values.name]);

  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className={`createTagModalWrapper ${
          tagsClass ? "tagsClassBackDrop" : ""
        }`}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="crossIcon"
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <img src={CrossModalCIcon} alt="crossIcon" />
        </IconButton>
        <DialogContent className="exportModalContent" dividers>
          <Box className="exportHeader">
            <Typography gutterBottom variant="h4">
              {MESSAGES.SETTINGS.TAGS.CREATE.TITLE}
            </Typography>
          </Box>
          <form
            className="updateFormWrapper"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Box className="editNameInputsWrapper">
              <InputLabel htmlFor="first-name" className="inputLabel">
                {MESSAGES.SETTINGS.TAGS.CREATE.TAG_LABEL}
              </InputLabel>
              <TextField
                placeholder={MESSAGES.SETTINGS.TAGS.CREATE.TAG_PLACEHOLDER}
                fullWidth
                name="name"
                autoComplete="off"
                onChange={formik.handleChange}
                className={
                  formik.errors.name && formik.touched.name
                    ? "inputField invalid"
                    : "inputField"
                }
                onBlur={realTimeValidation ? formik.handleBlur : undefined}
                onKeyDown={(e) => handleEnter(e)}
                value={formik.values.name.trimStart()}
              />
              {formik.touched.name && formik.errors.name ? (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {formik.errors.name}
                </Typography>
              ) : null}
              {customErrors.length > 0 ? (
                <Typography className="errorMessage">
                  <sup>*</sup>
                  {customErrors}
                </Typography>
              ) : null}
            </Box>
            <Box>
              <div className="tagsOuter">
                {tagsToCreate.map((el, index) => (
                  <div className="tag" key={index}>
                    <p>{el}</p>
                    <button>
                      <img
                        src={TemplateCrossCIcon}
                        alt={el}
                        onClick={(e) => handleChipsCross(e)}
                      />
                    </button>
                  </div>
                ))}
              </div>
            </Box>
            <Box className="editNameModalBtns">
              <Button onClick={onClose} className="btnCancel">
                {MESSAGES.SETTINGS.TAGS.CANCEL_BUTTON}
              </Button>
              <Button
                className="btnSave"
                type="submit"
                onClick={(e) => createNewTag(e)}
              >
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                      width: "25px !important",
                      height: "25px !important",
                    }}
                  />
                ) : (
                  MESSAGES.SETTINGS.TAGS.CREATE.SUBMIT_BUTTON
                )}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
};

export default CreateTagModal;
